import { createRoot } from 'react-dom/client';
import './styles/index.css';

async function loadConfig() {
  const configFile = `/env-config.${__REACT_APP_BUILD_VERSION__}.json`;
  const result = await fetch(configFile);
  return result.json() as Promise<Window['_env_']>;
}

(async function () {
  window._env_ = await loadConfig();
  const { App } = await import('./app');

  const root = createRoot(document.getElementById('root')!);
  root.render(<App />);
})();
